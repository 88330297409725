import React from "react"
import {withTranslation} from "react-i18next";
import axios from "axios";

class Imprint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imp: false
		};
	}
	componentDidMount() {
		if(!this.state.imp){
			axios.get("https://api.m8werk.de?imp").then(res => {
				if(res && res.data){
					this.setState({
						imp: res.data
					})
				}
			})
		}
	}
	
	render() {
		const {imp} = this.state;
		return (
			<div id="imprint">
				<div className="impressum">
					<h3>ImmoSaar24</h3>
					<p>{imp.name}<br/>
						{imp.degree}</p>
					<p>{imp.street}<br/>
						{imp.city}<br/>
						{imp.id}</p>
					<h4>KONTAKT</h4>
					<p>{imp.email}<br/>
						{imp.web}</p>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Imprint)
