import React from "react";
import {withTranslation} from "react-i18next";
import DataProtection from "./DataProtection";
import axios from "axios";

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showDataProtection: false,
			footer: false
		}
	}
	
	componentDidMount() {
		if(!this.state.footer){
			axios.get("https://api.m8werk.de?footer=immosaar24").then(res => {
				if(res && res.data){
					this.setState({
						footer: res.data
					})
				}
			})
		}
	}
	
	render() {
		const {t} = this.props;
		const {showDataProtection, footer} = this.state;
		return (<div id="footer" className="section footer shadow">
			{showDataProtection ? <DataProtection closeDataProtection={() => this.setState({showDataProtection: false})}/> : ""}
			<div className="container">
				<div className="content">
					<div className="column">
						<h4>Navigation</h4>
						<ul>
							{this.props.navs.map((n, i) => <li key={"footer-nav-" + i}>{t(n)}</li>)}
						</ul>
					</div>
					<div className="column"/>
					<div className="column">
						<h4>Social</h4>
						<div className="socials">
							<span className="icon-social-facebook"/>
							<span className="icon-social-twitter"/>
							<span className="icon-social-linkedin"/>
						</div>
					</div>
				</div>
				<div className="copyright">
					<p style={{display: "flex", alignItems:"center"}}>&copy; {footer.year}&nbsp;
						{footer.website}
						<img src={footer.logo} style={{height: "20px", width: "auto", margin: "0 0.5rem"}}/>
						<a target="_blank" href="https://ander5.de">{footer.text}</a></p>
					<ul>
						<li onClick={() => this.setState({
							showDataProtection: !this.state.showDataProtection
						})}>Datenschutz
						</li>
					</ul>
				</div>
			</div>
		</div>)
	}
}

export default withTranslation()(Footer);
