import React from "react"
import {MdClose} from "react-icons/md";

class Viewer extends React.Component{
    render(){
        return (
            <div className="viewer">
                <div className="close" onClick={() => this.props.close()}><MdClose/></div>
                {/*<div className="image" style={{backgroundImage: `url(${this.props.image})`}}/>*/}
                <img src={this.props.image}/>
            </div>
        )
    }
}
export default Viewer;
