import React from "react";
import {FiChevronRight} from "react-icons/fi";
import axios from 'axios';
import { withTranslation } from "react-i18next";
import data from "./contactFields.json";
const API_PATH = './sendmail/sendmail.php';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailSent: false,
            required: [],
            contactFields: {},
            submitted: false,
            missing: [],
            captcha: "",
            wrongCaptcha: true,
            successful: false,
            error: ""
        }
    }

    componentDidMount() {
        const contactFields = data.contactFields.reduce((a, b) => {a[b.name] = ""; return a},{});
        const required = data.contactFields.filter((f) => f.required).reduce((a, b, i) => {a[i] = b.name; return a},[]);
        this.setState({
            contactFields: contactFields,
            required: required
        })
        this.createCaptcha();
    }

    handleInput = (e, field) => {
        e.preventDefault();
        const value = e.currentTarget.value;
        let missing = this.state.missing;
        if(value !== ""){
            missing = this.state.missing.filter(x => x !== field);
        }
        if(field === "captcha" && value === this.state.captcha){
            this.setState({
                wrongCaptcha: false
            })
        }
        const {contactFields} = this.state;
        this.setState({
            contactFields: {
                ...contactFields,
                [field]: value
            },
            missing: missing
        })
        if(this.state.submitted) {
            this.setState({
                submitted: false
            })
        }
        return true;
    }

    handleFormSubmit = e => {
        e.preventDefault();
        const {required, contactFields, wrongCaptcha} = this.state;
        let missing = required.filter(f => contactFields[f] === "");
        if(missing.length > 0){
            this.setState({
                missing: missing
            })
        }else{
            if(!wrongCaptcha){
                axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: {'content-type': 'application/json'},
                    data: this.state
                })
                    .then(result => {
                        this.setState({
                            mailSent: result.data.sent,
                            successful: true
                        })
                    })
                    .catch(error =>
                        this.setState({error: error.message, successful: false})
                    );
            }
            this.setState({
                submitted: true
            })
            this.createCaptcha();
        }
        return true;
    };
    createCaptcha = () => {
        const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lengthOtp = 6;
        const captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            const index = Math.floor(Math.random() * charsArray.length + 1);
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        const canvas = this.canvas;
        canvas.width = canvas.getBoundingClientRect().width+10;
        canvas.height = canvas.getBoundingClientRect().height;
        const ctx = canvas.getContext("2d");
        ctx.font = "22px Georgia";
        ctx.strokeStyle= "#FFF";
        ctx.textAlign = "center";
        ctx.strokeText(captcha.join(""), canvas.width/2, canvas.height/3*2);
        this.setState({
            captcha: captcha.join("")
        });
    }

    render() {
        const {t} = this.props;
        const {contactFields, missing, wrongCaptcha, submitted, successful} = this.state;
        const fields = [];
        data.contactFields.map((f, i) => {
            fields.push(
                <label className={missing.includes(f.name) || (f.type === "captcha" && wrongCaptcha && submitted)?"missing":""} key={f.name+"-"+i}>
                    <span key={"input-"+i}>
                        {t(f.name)}
                        {f.required ?
                            <span className="required">({t('required')})</span>
                            : ""}
                    </span>
                    {f.type === "textarea" ?
                        <div className={"input-wrapper "+f.name}>
                            <textarea type="text" onChange={e => this.handleInput(e, f.name)} value={contactFields[f.name] ? contactFields[f.name]:""} key={f.name+"-input"} rows={6}/>
                        </div>
                        :
                            <div className={"input-wrapper "+f.name}><input type="text" value={contactFields[f.name] ? contactFields[f.name] : ""} onChange={e => this.handleInput(e, f.name)} key={f.name+"-input"}/>
                                {f.type === "captcha" ?
                                <span className="captcha-code" key={"BLA"}>
                                    <canvas ref={e => this.canvas = e}/>
                                </span>
                            :""}
                            </div>

                    }
                </label>
            )
            return true;
        })
        return (
            <div className="contact-form">
                <div className="headline">
                    {
                        this.state.error
                    }
                </div>
                <div>
                    {this.state.mailSent &&
                    <div>{t('thank-you')}</div>
                    }
                </div>
                {missing.length > 0 &&
                    <div className="fill-missing">{t('fill-missing')}</div>
                }
                {wrongCaptcha && submitted &&
                    <div className="wrong-captcha">{t('wrong-captcha')}</div>
                }
                <form>
                    {fields}
                </form>
                <div className="btn-group">
                    {!successful ? <div className="btn" onClick={e => this.handleFormSubmit(e)}>{t("send-your-message")}<FiChevronRight/></div>:<p>Thank you!</p>}
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContactForm);
