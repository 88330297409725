import React from "react";

const images = require("../assets/images.json");

class Certificates extends React.Component{
    render(){
        return(
            <div id="certificates" className="section">
                <div className="container">
                    <div className="certificates-inner">
                        <div className="content">
                            <h2>Bankkaufmann (IHK)</h2>
                            <h3>Unternehmensberater</h3>
                            <p>
                                <span>Immobiliendarlehensvermittler - Erlaubnis gem. §34i GewO – IHK Saarland</span>
                                <span>Registernummer D-W-170-TRRM-60</span>
                            </p>
                            <p>
                                <span>Finanzanlagenvermittler - Erlaubnis gem. §34f GewO – IHK Saarland</span>
                                <span>Registernummer D-F-170-7H9M-82</span>
                            </p>
                            <p>
                                <span>Immobilienmakler - Erlaubnis gem. §34c GewO</span>
                                <span>Landkreis Neunkirchen</span>
                            </p>
                            <p>
                                <span>Darlehensvermittler - Erlaubnis gem. §34c GewO</span>
                                <span>Landkreis Neunkirchen</span>
                            </p>
                        </div>
                        <div className="images">
                            {
                                Object.keys(images.certificates).map((key, i) =>
                                    <div className="certificate-wrapper"
                                         key={"cert-"+i}
                                         onClick={() => this.props.viewer(images.certificates[key])}
                                    ><div className="certificate" style={{backgroundImage: `url('${images.certificates[key]}')`}}/></div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Certificates;
