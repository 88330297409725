import React from "react"
import {withTranslation} from "react-i18next";
class Financing extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div id="financing" className="section">
                <div className="container">
                    <h2>{t('financing')}</h2>
                    <h3>{t('we-are-your-partner')}</h3>
                </div>
                <div className="info-wrapper">
                    <div className="container">
                        <div className="info">
                            <div className="side">
                                <h4>{t('real-estate-financing')}</h4>
                                <p>{t('real-estate-financing-text')}</p>
                            </div>
                            <div className="side img right">
                                <div className="img-wrapper">
                                    <img src={"https://previews.123rf.com/images/scyther5/scyther51604/scyther5160400057/55613532-insurance-haus-live-car-schutz-sch%C3%BCtzen-konzepte.jpg"} alt="financing"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="info-wrapper">
                    <div className="container">
                        <div className="info">
                            <div className="side img left">
                                <div className="img-wrapper">
                                    <img src={"https://previews.123rf.com/images/mkphotoshu/mkphotoshu1706/mkphotoshu170600134/81288915-team-von-gesch%C3%A4ftspartnern-monatlichen-treffen-zu-planen-ein-darlehen-zu-kaufen-immobilien-und-bewegli.jpg"} alt="financing"/>
                                </div>
                            </div>
                            <div className="side">
                                <h4>{t('commercial-financing')}</h4>
                                <p>{t('commercial-financing-text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="info-wrapper">
                    <div className="container">
                        <div className="info">
                            <div className="side">
                                <h4>{t('credit')}</h4>
                                <p>{t('credit-text')}</p>
                            </div>
                            <div className="side img right">
                                <div className="img-wrapper">
                                    <img src={"https://previews.123rf.com/images/alexraths/alexraths1602/alexraths160200027/52325958-immobilienmakler-die-h%C3%A4nde-mit-kunden-nach-vertragsunterzeichnung-sch%C3%BCtteln.jpg"} alt="financing"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Financing)
