import React from "react"
import ContactForm from "./ContactForm"
import Imprint from "./Imprint";
import {withTranslation} from "react-i18next";

class Contact extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div className="section" id="contact">
                <div className="bg-image"/>
                <div className="container">
                    <div className="contact">
                        <h2>{t("contact")}</h2>
                        <ContactForm/>
                        <Imprint/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Contact);
