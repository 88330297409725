import React from "react"
import {withTranslation} from "react-i18next";
import axios from "axios";
import Property from "./Property";

const apiPath = "https://immosaar24.de/db/immo-db.php?data="

class RealEstate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			properties: [],
			property: false
		}
	}
	
	componentDidMount() {
		this.fetchProperties();
	}
	
	fetchProperties() {
		axios.get(`${apiPath}properties`, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(res => {
			this.setState({
				properties: res.data
			})
		}).catch(error => console.log("ERROR", error));
	};
	
	formatPrice(number) {
		return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(number);
	}
	
	getValues(content, values) {
		const {t} = this.props;
		const result = [];
		values.map(v => {
			if (content[v] && content[v].value)
				result.push(<p key={v}>
					<span>{t(v)}</span>
					<span>{content[v].unit === "€" ? this.formatPrice(content[v].value) : content[v].value} {content[v].unit !== "€" ? content[v].unit : ""}</span>
				</p>);
		})
		return result;
	}
	
	render() {
		const {properties, property} = this.state;
		const {t} = this.props;
		return (
			<div id="real-estate" className="section">
				{property && <Property close={() => this.setState({property: false})} apiPath={apiPath} id={property}/>}
				<div className="container">
					<h2>{t('real-estate')}</h2>
					<div className={"properties" + (properties && properties.length <= 3 ? " between" : " start")}>
						{
							properties && properties.length > 0 && properties.map((p, i) => {
								const content = JSON.parse(p.content);
								return (
									<div className={"property" + (properties.length < 3 ? " between" : " start")} key={"property-" + i} onClick={() => this.setState({property: p.id})}>
										<div className="image" style={{backgroundImage: `url(${p.image})`}}>
											{content.tags.value && <div className="tags">
												{content.tags.value.split(",").map((t, i) => <span key={"tag-" + i}>{t}</span>)}
											</div>}
										</div>
										<div className="content">
											{content.titel.value && <h3>{content.titel.value}</h3>}
											<div className="infos">
												{this.getValues(content, ["kaufpreis", "zimmer", "wohnflaeche", "grundstueck"])}
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(RealEstate)
