import React from "react"
import {withTranslation} from "react-i18next";
import partner from "../assets/img/icons/partner.svg";
import leasing from "../assets/img/icons/leasing.svg";
import financing from "../assets/img/icons/financing.svg";
import money from "../assets/img/icons/money.svg";

class About extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div id="about" className="section">
                <div className="back-top-bar"></div>
                <div className="container">
                    <h2 className="main">{t('your-partner')}</h2>
                    <div className="content">
                        <div className="top">
                            <div className="left">
                                <h3>{t('competence-team')}</h3>
                                <p>{t('competence-team-text')}</p>
                            </div>
                            <div className="top-box">
                                <div className="icon"><img alt={t('missing-image')} src={money}/></div>
                                <h2>{t('your-money')}</h2>
                                <h3>{t('your-money-text')}<strong>{t('us')}</strong></h3>
                            </div>
                        </div>
                        {/*<div className="divider"/>*/}
                        {/*<div className="bottom">*/}
                        {/*    /!*<h2>{t('financial-freedom')}</h2>*!/*/}
                        {/*    <div>*/}
                        {/*        <img alt={t('missing-image')} src={partner}/>*/}
                        {/*        <h3>{t('your-partner-2')}</h3>*/}
                        {/*        <p>{t('your-partner-text')}</p>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <img alt={t('missing-image')} src={leasing}/>*/}
                        {/*        <h3>{t('leasing-partner')}</h3>*/}
                        {/*        <p>{t('leasing-partner-text')}</p>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <img alt={t('missing-image')} src={financing}/>*/}
                        {/*        <h3>{t('financing-partner')}</h3>*/}
                        {/*        <p>{t('financing-partner-text')}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tiles">*/}
                        {/*    <div className="tile">*/}
                        {/*        <h2>{t('commercial')}<br/>{t('property')}</h2>*/}
                        {/*    </div>*/}
                        {/*    <div className="tile">*/}
                        {/*        <h2>{t('residential')}<br/>{t('property')}</h2>*/}
                        {/*    </div>*/}
                        {/*    <div className="tile">*/}
                        {/*        <h2>{t('capital')}<br/>{t('investment')}</h2>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="tiles">
                            <div className="tile">
                                <img alt={t('missing-image')} src={partner}/>
                                <h4>{t('your-partner-2')}</h4>
                                <p>{t('your-partner-text')}</p>
                            </div>
                            <div className="tile">
                                <img alt={t('missing-image')} src={leasing}/>
                                <h4>{t('leasing-partner')}</h4>
                                <p>{t('leasing-partner-text')}</p>
                            </div>
                            <div className="tile">
                                <img alt={t('missing-image')} src={financing}/>
                                <h4>{t('financing-partner')}</h4>
                                <p>{t('financing-partner-text')}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation()(About)
