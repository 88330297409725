import React from "react";
import {withTranslation} from "react-i18next";
import {MdClose} from "react-icons/md";
import axios from "axios";
import Loading from "../assets/Loading";
import {Scrollbars} from "react-custom-scrollbars";

class Property extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            property: false,
            image: ""
        }
    }

    componentWillUnmount() {
        this.setState({
            property: false
        })
    }

    componentDidMount() {
        this.fetchProperty();
    }

    fetchProperty() {
        const url = `${this.props.apiPath}property&id=${this.props.id}`;
        axios.get(url, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                this.setState({
                    property: {
                        content: JSON.parse(res.data[0].content), images: JSON.parse(res.data[0].images)
                    },
                    image: JSON.parse(res.data[0].images)[0]
                });
            })
            .catch(error => this.props.showToast("error", "Fehler", `Es ist ein Fehler beim Laden der Immobilie aufgetreten. ${error}`));
    };

    formatPrice(number) {
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(number);
    }

    getValues(content, values) {
        const {t} = this.props;
        const result = [];
        values.map(v => {
            if (content[v] && content[v].value) result.push(<p key={v}>
                <span>{t(v)}</span>
                <span>{content[v].unit === "€" ? this.formatPrice(content[v].value) : content[v].value} {content[v].unit !== "€" ? content[v].unit : ""}</span>
            </p>);
        })
        return result;
    }

    clearNumber(str) {
        return parseFloat(str.replace(".", "").replace(",", "."));
    }

    skala() {
        const colorSteps = [0, 30, 50, 75, 100, 130, 160, 200, 250, 270];
        const chars = ["A+", "A", "B", "C", "D", "E", "F", "G", "H"];
        const steps = [];
        const colors = []
        for (let i = 1; i < colorSteps.length; i++) {
            const value = 100 / 270 * (colorSteps[i] - colorSteps[i - 1]);
            colors.push(<span key={"color-" + i} style={{width: value + "%"}}>{chars[i - 1]}</span>);
        }
        for (let i = 0; i <= 10; i++) {
            steps.push(<span key={"step-" + i}>{i === 10 ? "> " : ""}{i * 25}</span>)
        }
        return <>
            <div className="colors">{colors}</div>
            <div className="values">{steps}</div>
        </>;
    }

    calcPrice(content) {
        const {t} = this.props;
        const preis = this.clearNumber(content.kaufpreis.value);
        const makler = this.clearNumber(content.maklerprovision.value);
        const steuer = this.clearNumber(content.grunderwerbssteuer.value);
        const notar = this.clearNumber(content.notarkosten.value);
        const eintrag = this.clearNumber(content.grundbucheintrag.value);
        const maklerPer = Math.round(preis * (makler / 100));
        const steuerPer = Math.round(preis * (steuer / 100));
        const notarPer = Math.round(preis * (notar / 100));
        const eintragPer = Math.round(preis * (eintrag / 100));
        const nebenkostenPer = makler + steuer + notar + eintrag;
        const nebenkosten = maklerPer + steuerPer + notarPer + eintragPer;
        return (<div className="price-calc">
            <div className="bars">
                <p><span>{t("kaufpreis")}</span><span>{this.formatPrice(content.kaufpreis.value)}</span></p>
                <p><span>{t("nebenkosten")} {nebenkostenPer}%</span><span>{this.formatPrice(nebenkosten)}</span></p>
                <div className="price-bar"/>
                <div className="add-price">
                    <div style={{width: 100 / nebenkosten * maklerPer + "%"}}/>
                    <div style={{width: 100 / nebenkosten * steuerPer + "%"}}/>
                    <div style={{width: 100 / nebenkosten * notarPer + "%"}}/>
                    <div style={{width: 100 / nebenkosten * eintragPer + "%"}}/>
                </div>
            </div>
            <div className="values">
                {content.maklerprovision.value && <p><span>{t('maklerprovision')}</span><span>{content.maklerprovision.value}%</span><span>{this.formatPrice(maklerPer)}</span></p>}
                {content.grunderwerbssteuer.value && <p><span>{t('grunderwerbssteuer')}</span><span>{content.grunderwerbssteuer.value}%</span><span>{this.formatPrice(steuerPer)}</span></p>}
                {content.notarkosten.value && <p><span>{t('notarkosten')}</span><span>{content.notarkosten.value}%</span><span>{this.formatPrice(notarPer)}</span></p>}
                {content.grundbucheintrag.value && <p><span>{t('grundbucheintrag')}</span><span>{content.grundbucheintrag.value}%</span><span>{this.formatPrice(eintragPer)}</span></p>}
            </div>
            <div className="total-price"><span>{t('gesamtpreis')}</span><span>{this.formatPrice(preis + nebenkosten)}</span></div>
        </div>)
    }

    renderText(text, title, i) {
        const {t} = this.props;
        return (<div className="text" key={"text-"+i}>
            <h4>{t(title)}</h4>
            <div className="rows-2">
                <div>{text}</div>
            </div>
        </div>)
    }

    render() {
        const {t} = this.props;
        const {property} = this.state;
        const content = property.content;
        const images = property.images;
        const texts = ["beschreibung", "ausstattung-beschreibung", "lage-beschreibung", "sonstiges-beschreibung"]
        console.log(property);
        return (<div className="property-wrapper">
            {property ? <div className="property">
                <div className="close" onClick={() => this.props.close()}>
                    <MdClose/>
                </div>
                <div className="property-inner">
                    <div className="content">
                        <Scrollbars>
                            <div className="content-inner">
                                <h2>{content.titel.value}</h2>
                                <h3>{content.adresse.value}</h3>
                                {content.tags.value && <div className="tags">
                                    {content.tags.value.split(",").map((t, i) => <span key={"tag-" + i}>{t}</span>)}
                                </div>}
                                <div className="bold"><span>{t('kaufpreis')}</span><span>{this.formatPrice(content.kaufpreis.value)}</span></div>
                                <div className="line"/>
                                <div className="rows-2">
                                    {this.getValues(content, ["typ", "etagenanzahl", "wohnflaeche", "nutzflaeche", "grundstueck", "bezugsfrei-ab", "zimmer", "schlafzimmer", "badezimmer", "garage-stellplatz"])}
                                </div>
                                <div className="line"/>
                                <div>
                                    {this.calcPrice(content)}
                                </div>
                                <h4>{t("bausubstanz-energieausweis")}</h4>
                                <div className="rows-2">
                                    {this.getValues(content, ["baujahr", "modernisierungsanierung", "objektzustand", "ausstattung", "heizungsart", "wesentliche-energietraeger", "energieausweis", "energieausweistyp", "endenergiebedarf", "energieeffizienzklasse"])}
                                </div>
                                <div className="skala">
                                    <div className="mark" key="current" style={{left: 100 / 270 * parseFloat(content.endenergiebedarf.value) + "%"}}>
                                        <strong>{content.endenergiebedarf.value}</strong> <span className="small">{content.endenergiebedarf.unit}</span>
                                    </div>
                                    {this.skala()}
                                </div>
                                {texts.map((text, i) =>
                                    content[text] && content[text].value && content[text].value !== "" ? this.renderText(content[text].value, text, i) : ""
                                )}
                            </div>
                        </Scrollbars>
                    </div>
                    <div className="images">
                        <div className="image-wrapper">
                            <div className="image" style={{backgroundImage: `url('${this.state.image}')`}}/>
                            <div className="image-blur" style={{backgroundImage: `url('${this.state.image}')`}}/>
                        </div>
                        <div className="thumbs">
                            {
                                images.map((image, i) =>
                                    <div className="thumb-wrapper" key={"thumb-"+i}>
                                        <div className="thumb" style={{backgroundImage: `url('${image}')`}}
                                        onClick={() => this.setState({image: image})}/>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div> : <Loading/>}
        </div>)
    }
}

export default withTranslation()(Property);
