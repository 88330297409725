import React from "react"

class Loading extends React.Component{
    render(){
        return (
            <div className="loader">
                <div className="loading">
                    <div className="load load-c1"/>
                    <div className="load load-c2"/>
                    <div className="load load-c4"/>
                    <div className="load load-c3"/>
                </div>
            </div>
        )
    }
}
export default Loading;
