import React from "react"
import TopNav from "./TopNav";
import {Scrollbars} from "react-custom-scrollbars";
import Welcome from "./Welcome";
import About from "./About";
import Financing from "./Financing";
import RealEstate from "./RealEstate";
import Contact from "./Contact";
import Leasing from "./Leasing";
import Footer from "./Footer";
import Certificates from "./Certificates";
import Viewer from "../assets/Viewer";

const navs = [
    "welcome", "financing", "real-estate", "leasing", "contact"
];

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollTopId: 5000,
            sections: [],
            currentView: 0,
            viewer: false
        }
    }
    componentDidMount() {
        if(this.state.sections.length === 0){
            this.setState({
                sections: document.querySelectorAll('.section')
            })
        }
        // this.scrollToId("real-estate")
    }
    scrollToId(id, view){
        const item = document.getElementById(id).getBoundingClientRect();
        this.scrollBar.view.scroll({
            top: item.top + this.scrollBar.getScrollTop(),
            behavior: 'smooth'
        });
        this.setState({
            currentView: view,
            scrollable: false
        })
    }
    endScroll(){
        this.setState({
            scrollable: true
        })
    }

    handleScroll(){
        if(this.state.scrollable){
            const {sections} = this.state;
            let view = this.state.currentView;
            for(let i = 0; i < sections.length; i++){
                const current = sections[i].getBoundingClientRect();
                const next = sections[i+1] ? sections[i+1].getBoundingClientRect() : sections[i].getBoundingClientRect();
                if(next && next.top < window.innerHeight && (current.top * -1) < window.innerHeight){
                    if((current.top * -1) > next.top) {
                        view = i+1;
                    }else if((current.top * -1) < next.top){
                        view = i;
                    }
                }else if(current.top > window.innerHeight && view === i){
                    view = view -1;
                }
            }
            // if(view !== this.state.currentView){
                this.setState({
                    currentView: view
                })
            // }
        }
    }

    setViewer(image){
        this.setState({
            viewer: image
        })
    }

    render() {
        const {sections, currentView, viewer} = this.state;
        const currentNav = sections[currentView] ? sections[currentView].id : "";
        return (
            <div className="main">
                { viewer && <Viewer image={viewer} close={() => this.setViewer(false)}/>}
                <TopNav navs={navs} scrollToId={(id, view) => this.scrollToId(id, view)} currentView={currentNav}/>
                <div className="content">
                    <Scrollbars
                        autoHeightMin={100}
                        autoHeightMax={"100%"}
                        ref={ e => this.scrollBar = e }
                        onScroll={() => this.handleScroll()}
                        onScrollStop={() => this.endScroll()}
                    >
                        <Welcome scrollToId={(id, view) => this.scrollToId(id, view)}/>
                        <About/>
                        <Financing/>
                        <RealEstate/>
                        <Certificates viewer={(image) => this.setViewer(image)}/>
                        <Leasing/>
                        <Contact/>
                        <Footer navs={navs}/>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default Content
