import React from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import SVG from "../assets/img/SVG";


class TopNav extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        }
    }

    changeLanguage = (lng) => {
        i18next.changeLanguage(lng)
    }

    render(){
        const languages = i18next.languages.sort((a, b) => a.localeCompare(b));
        const {t, scrollToId} = this.props;
        const {} = this.state;
        return(
            <div className="topnav">
                <div id="navbg"/>
                <div id="nav">
                    <div className="container">
                        <div className="nav">
                            <div className="left">
                                <span className="line"><span>{t('call')}</span>0815 123456</span>
                                <span className="line"><span>{t('write')}</span>info@immosaar24.de</span>
                                <div className="logo"/>
                            </div>
                            <div className={"burger"+(this.state.showMenu?" active":"")} onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                                <SVG type="Burger" width="1.4rem"/></div>
                            <div className={"menu-wrapper"+(this.state.showMenu?" true":" false")}>
                                <div className="link-group">
                                    <div className="languages" key="languages">
                                        {languages.map((l, i) =>
                                            <span key={"lang-"+i} className={l === i18next.language ? "active":""} onClick={() =>
                                                this.changeLanguage(l)
                                            }>{l}</span>
                                        )}
                                    </div>
                                    {this.props.navs.map((n, i) =>
                                        <span key={"nav-"+i} onClick={() => {
                                            scrollToId(n, i)
                                            this.setState({
                                                showMenu: false
                                            })
                                        }} className={n === this.props.currentView ? "active":""}>{t(n)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(TopNav);
