import React from "react"
import Burger from "./Burger";

const SVG = (props) => {
    switch (props.type){
        case "Burger":
            return(
                <Burger {...props}/>
            )
        default:
            return (
                ""
            );
    }
}

export default SVG
