import * as React from "react"

function Burger(props) {
    return (
        <svg viewBox="0 0 15.66 12.6" {...props}>
            <path d="M0 0h15.66v1.53H0zM0 5.53h15.66v1.53H0zM0 11.07h15.66v1.53H0z"
            />
        </svg>
    )
}

export default Burger
