import React from "react"
import {withTranslation} from "react-i18next";

class Leasing extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div id="leasing" className="section">
                <div className="container">
                    <h2>{t('leasing')}</h2>
                    <p>Sie wollen mühelos, blitzschnell und glasklar den passenden, steuerlich und
                        wirtschaftlich sinnvollen Leasingvertrag beim richtigen Anbieter für Ihr
                        Objekt? Genau dabei helfe ich Ihnen! Stochern Sie nicht im Nebel der vielen
                        Leasingangebote! Aufwändige Einzelgespräche mit verschiedenen Anbietern
                        gehören der Vergangenheit an. Nutzen Sie Ihre kostbare Zeit für Ihr
                        Hauptgeschäft. Als unabhängiger Leasingmakler bieten wir Ihnen kompetente
                        und umfassende Beratung, sowie grundlegendes Know-How in allen Fragen rund
                        um’s B2B-Leasing. Wir begleiten Sie vom Anfang bis zum Ende der Leasingzeit.
                    </p>
                    <p>
                        Frei wie ein Vogel, ohne Verpflichtung oder Bindung zu einer
                        Leasinggesellschaft oder Bank vertreten wir nur Ihre Interessen. Für
                        Leasingnehmer völlig kostenfrei! Sie erhalten fast immer die zu Ihrem
                        Finanzierungsobjekt passende Leasinggesellschaft auch für ungewöhnliche
                        Investionsgüter.   Nicht jede Leasinggesellschaft ist für jedes
                        Investitionsobjekt gleich gut geeignet! Greifen Sie auf unsere jahrelange
                        Erfahrung auch in diesem Bereich zurück.
                    </p>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Leasing)
