import React from "react";
import { withTranslation } from "react-i18next";

class Welcome extends React.Component{
    render(){
        // const {t} = this.props;
        return (
            <div className="section full-height shadow" id="welcome">
                <div className="container">
                    <div className="slide">
                        <h1>
                            <div className="frame"><span/></div>
                            from vision to mission
                        </h1>
                        <h2>beyond the business</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        <p className="link">View<span className="icon-arrow-left arrow"/></p>
                    </div>
                    <span className="icon-arrow-down white move-down"/>
                </div>

            </div>
        )
    }
}
export default withTranslation()(Welcome);
